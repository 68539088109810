import './styles.scss'; 

type TProps = {
  className: string,
  children?: any,
  style?: any,
}

const EmptyTicket = function(props: TProps) {
  const  { className, children, style } = props;

  return (
    <div style={style} className={`ticket-container ${className}`}>
      {children}
    </div>
  )
}

export default EmptyTicket;
