import Header from '../../components/Header';
import PlayNowSection from '../../components/PlayNowSection';
import BeFirstSection from '../../components/BeFirstSection';
import LegendaryGameSection from '../../components/LegendaryGameSection';
import ExpandingGameSection from '../../components/ExpandingGameSection';
import DownloadsSection from '../../components/DownloadsSection';
import BloggersSection from '../../components/BloggersSection';
import DoWeb3HistorySection from '../../components/DoWeb3HistorySection';
import Footer from '../../components/Footer';

import { THeaderProps } from '../../components/Header';
import { TFooterProps } from '../../components/Footer';

import './styles.scss';
import PreloadContent from '../../components/PreloadContent';

type TProps = {
  header: THeaderProps,
  footer: TFooterProps
}

function LandingPage(props: TProps) {
  const { header, footer } = props;

  return (
    <>
      <Header {...header} />
      <PlayNowSection />
      <BeFirstSection />
      <LegendaryGameSection />
      <ExpandingGameSection />
      <DownloadsSection />
      <BloggersSection />
      <DoWeb3HistorySection />
      <Footer {...footer} />
    </>
  );
};

export default LandingPage;
