import EmptyTicket from '../EmptyTicket';
import Button from '../Button';
import Separator from '../Separator';

import { WHITEPAPER_URL } from '../../';

import icon from './Rectangle 3339.png'

import './styles.scss';

export const handleLinkClick = (url: string) => {
  window?.open(url, '_blank')?.focus();
}

const handleClick = () => {
  handleLinkClick(WHITEPAPER_URL);
}

function BeFirstSection() {
  return (
    <section>
      <div className="super-container">
        <EmptyTicket className="be-first-section-container flex-column flex flex-align-center bg-second-y">
          <div className="be-first-section__header flex flex-column flex-align-center">
            <h2>A new experience where Players Get rewards in NFTs and crypto tokens</h2>
            <Separator className="w100 bg-primary be-first-section__header-separator" />
          </div>
          <div className="bf-butt-container w100 flex flex-align-center flex-column">
            <Button onClick={handleClick} className="be-first-section__button" bgClassName="bg-second-y hover-bg-color-secondary-m">
              <div className="flex be-first-section__button-inner-container">
                <img src={icon} />
                <h3 className="text-weigth-bold uppercase text-primary">whitepaper</h3>
              </div>
            </Button>
          </div>
        </EmptyTicket>
      </div>
    </section>
  );
};

export default BeFirstSection;
