import { useState, MouseEventHandler } from 'react';
import Socials from '../Socials';
import Trademark from '../Trademark';

import './styles.scss';
import logo from './TotM Logo.svg';

export type THeaderProps = {
  logoLink: string,
  whitepaperLink?: string,
  onRegistration?: MouseEventHandler<HTMLButtonElement>,
}

const Header = function(props: THeaderProps) {
  const {logoLink, whitepaperLink, onRegistration } = props;

  const [toggled, setToggleed] = useState(false);

  const handleToggle = () => {
    setToggleed(!toggled);
  }

  const showNav = whitepaperLink && onRegistration;

  return (
    <header className="header--sticky bg-primary">
      <div className="max-width-container height-inherit">
        <div className="header-container height-inherit flex flex-space-between flex-align-center">
          <a className="height-inherit flex" href={logoLink}>
            <img src={logo} className="header__logo"></img>
          </a>
          { showNav && <div>
            <button className="svg-button mobile-nav-toggle" aria-controls="nav-id" aria-expanded={toggled} onClick={handleToggle}>
              <span className="sr-only">Menu</span>
            </button>

             <nav className="header-nav">
              <ul id="nav-id" className="header-nav-ul flex" role="list" data-visible={toggled}>
                <li className="center">
                  <a className="header-nav__a" href={whitepaperLink} rel="noreferrer" target="_blank">
                    Whitepaper
                  </a>
                </li>
                <li className="center">
                  <button className="svg-button header-nav__a" onClick={onRegistration}>
                    Register your interest
                  </button>
                </li>
                { toggled && (
                  <div className="flex flex-column flex-align-center">
                    <Socials />
                    <Trademark />
                  </div>
                ) }
              </ul>
            </nav>
          </div> }
        </div>
      </div>
    </header>
  )
}

export default Header;
