import icon_dau from './Group 286.png';
import icon_globe from './icon_top10.png';

const data = [
  {
    header: 'Daily active users',
    body: {
      title: '2M+',
      subtitle: 'players',
      text: 'enjoy sticky game every day',
      icon: icon_dau,
    }
  },
  {
    header: 'The most popular game',
    body: {
      title: 'top 10',
      subtitle: 'downloads',
      text: 'in the US',
      icon: icon_globe,
    }
  },
  {
    header: 'Monthly active users',
    body: {
      title: '20M+',
      subtitle: 'players',
      text: 'gave us best ratings',
      icon: icon_dau,
    }
  }
]

export default data;
