import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.scss';
import App from './App';
import LandingPage from '../src/pages/LandingPage';
import PrivacyPolicy from '../src/pages/PrivacyPolicy';
import TermsAndConditions from '../src/pages/TermsAndConditions';
import reportWebVitals from './reportWebVitals';

import { handleClick } from './components/PlayNowSection';

export const WHITEPAPER_URL = "https://wp.tomb.playgendary.com";
export const APP_STORE_LINK = "https://apps.apple.com/app/id1057889290";
export const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.playgendary.tom&hl=en_IE&gl=US";
export const PLAYGENDARY_ABOUT_LINK = "https://playgendary.com/en/about";
export const CONTACT_EMAIL = "contact@tombofthemask.io";
export const CONTACT_EMAIL_LINK = `mailto:${CONTACT_EMAIL}`;
export const COMPANY_NAME = "Made with Crypto Limited";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const rootPageData = {
  header: {
    logoLink: '#',
    whitepaperLink: WHITEPAPER_URL,
    onRegistration: handleClick, 
  },
  footer: {
    logoLink: '#',
    whitepaperLink: WHITEPAPER_URL,
    privacyPolicyLink: '/privacy-policy',
    termsAndConditionsLink: '/terms-and-conditions',
  }
}

const pageData = {
  header: {
    logoLink: "/",
  },
  footer: {
    logoLink: '/',
    whitepaperLink: WHITEPAPER_URL,
    privacyPolicyLink: '/privacy-policy',
    termsAndConditionsLink: '/terms-and-conditions',
  }
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App><LandingPage {...rootPageData} /></App>} />
        <Route path="/privacy-policy" element={<App><PrivacyPolicy {...pageData} /></App>} />
        <Route path="/terms-and-conditions" element={<App><TermsAndConditions {...pageData} /></App>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
