
import { useState } from 'react';
import EmptyTicket from '../EmptyTicket';

import './styles.scss';

import screenshot from './dog_480_15.gif';
import Separator from '../Separator';

import face_icon from '../ExpandingGameSection/Group 286.png'

import Form from '../Form';

const DoWeb3HistorySection = function() {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    return setSubmitted(true);
  }

  const formArea = () => {
    if (!submitted) {
      return (<Form onSubmit={handleSubmit}/>)
    }

    return (
      <>
        <div className="flex h100 flex-column flex-align-center do-web3__flex-just-center">
          <h2 className="text-secondary-y text-align-center">Thank you for your interest</h2>
          <img className="do-web3__thx-icon" src={face_icon} />
        </div>
      </>
    )
  }

  return (
    <section id="register-interest">
      <div className="super-container">
        <EmptyTicket className="bg-accent-g w100 flex">
          <div className="flex w100 do-web3__wrap">
            <div className="flex w100">
              <img className="w100 register-interest-gif"src={screenshot}/>
            </div>
            <div className="reg-int__cont flex flex-column w100 do-web3-gap">
              <h1>do web3 history with us</h1>
              <Separator className="w100 bg-primary"/>
              <div className="h100 w100">
                { formArea() }
              </div>
            </div>
          </div>
        </EmptyTicket>
      </div>
    </section>
  )
}

export default DoWeb3HistorySection;
