import EmptyTicket from '../EmptyTicket';
import Separator from '../Separator';

import './styles.scss';


type TProps = {
  header: string,
  body: {
    title: string,
    subtitle: string,
    text: string,
    icon: string,
  }
}

function ExpandingGameTicket (props: TProps) {
  const { header, body } = props

  return (
    <EmptyTicket className="bg-accent-c expanding-ticket">
      <div className="super-container flex-column flex">
        <h3 className="text-weigth-bold uppercase expanding-ticket__header">{header}</h3>
        <Separator className="w100 bg-primary" />
        <div className="flex flex-align-center expanding-ticket__flex flex-space-between w100">
          <div>
            <h1 className="uppercase text-weigth-bold">{body.title}</h1>
            <h3 className="text-weigth-bold">{body.subtitle}</h3>
          </div>
          <img src={body.icon} className="exp-g__icon"/>
        </div>
        <h4>{body.text}</h4>
      </div>
    </EmptyTicket>
  );
};

export default ExpandingGameTicket;
