import './styles.scss';

import img_1 from "../BloggersSection/1.gif";
import img_2 from "../BloggersSection/2.gif";
import img_3 from "../BloggersSection/3.gif";
import img_4 from "../BloggersSection/4.gif";
import img_5 from "../BloggersSection/5.gif";
import img_6 from "../BloggersSection/6.gif";
import img_7 from "../BloggersSection/3_logo.jpg";
import img_8 from "../BloggersSection/spectex-logo.jpg";
import img_9 from "../BloggersSection/widget_logo.jpg";
import img_10 from "../BloggersSection/str3123_logo.jpg";
import img_11 from "../BloggersSection/gul_n_kim_logo.jpg";
import img_12 from "../BloggersSection/lonniedos_logo.jpg";
import img_13 from "../ExpandingGameSection/Group 286.png";
import img_14 from "../ExpandingGameSection/icon_top10.png";
import img_15 from "../Header/icon_menu.png";
import img_151 from "../Header/icon_menu_close.png";
// TODO change svg to png or rebuild the button because svg is not cacheable by browsers
import img_18 from "../PlayNowSection/Char.png";
import img_19 from "../PlayNowSection/Char_m.png";
import img_20 from "../PlayNowSection/Char_s.png";
import img_21 from "../PlayNowSection/Coin.png";
import img_22 from "../PlayNowSection/Coin_m.png";
import img_23 from "../PlayNowSection/Coin_s.png";
import img_24 from "../PlayNowSection/cover_snake.png";
import img_25 from "../PlayNowSection/cover_snake_m.png";

export default function PreloadContent() {
  // preload all assets that appears in carousels and css background styles that appear after whatever action with the mentioned elements
  // * preload all assets for carousels and for PlayNowSection because it's easier to keep consistency if there will be changes in future

  return (
    <>
      { <div id="preload">
        <img src={img_7} />
        <img src={img_8} />
        <img src={img_9} />
        <img src={img_10} />
        <img src={img_11} />
        <img src={img_12} />
        <img src={img_13} />
        <img src={img_14} />
        <img src={img_15} />
        <img src={img_151} />
        <img src={img_18} />
        <img src={img_19} />
        <img src={img_20} />
        <img src={img_21} />
        <img src={img_22} />
        <img src={img_23} />
        <img src={img_24} />
        <img src={img_25} />
      </div> }
    </>
  )
}
