import './styles.scss';

type TProps = {
  className?: string,
};

const Socials = function(props: TProps) {
  const { className } = props;

  return (
    <div className={`socials flex flex-row ${className}`}>
      <a href="https://discord.com/invite/gkP8yNyFS3" target="_blank" rel="noreferrer">
        <div className="social_icon--discord" />
      </a>
      <a href="https://twitter.com/TotMnft" target="_blank" rel="noreferrer">
        <div className="social_icon--twitter" />
      </a>
      <a href="https://t.me/TotMNFT" target="_blank" rel="noreferrer">
        <div className="social_icon--telegram" />
      </a>
    </div>
  )
}

export default Socials;
