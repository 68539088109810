import { useState } from 'react';
import CarouselDot from '../CarouselDot';

import './styles.scss';

type TProps = {
  slides: any[],
  slidesPerPage: number,
}

const Carousel = function(props: TProps) {
  const { slides, slidesPerPage } = props;
  const slidesAmount = slidesPerPage;

  const [storedSlides, setStoredSlides] = useState(slides);
  const [selectedPage, setSelectedPage] = useState(0);

  let distance: number = 1;
  const pagesAmount: number = Math.ceil(storedSlides.length / slidesAmount);

  const handleSelectSlidePage = (id: number) => {
    if (id == selectedPage) {
      return;
    }

    distance = Math.abs(selectedPage - id)

    if (id > selectedPage) {
      handleRight();
    } else {
      handleLeft();
    }

    setSelectedPage(id);
  }

  const handleRight = () => {
    let tempSlides = [...storedSlides];

    for (let i = 0; i < distance * slidesAmount; i += 1) {
      const first = tempSlides.shift();
      tempSlides = [...tempSlides, first];
    }

    setStoredSlides(tempSlides);

    const newId = selectedPage + 1 > pagesAmount - 1 ? 0 : selectedPage + 1;
    setSelectedPage(newId);
  }

  const handleLeft = () => {
    let tempSlides = [...storedSlides];

    for (let i = 0; i < distance * slidesAmount; i += 1) {
      const last = tempSlides.pop();
      tempSlides = [last, ...tempSlides];
    }

    setStoredSlides(tempSlides);

    const newId = selectedPage - 1 < 0 ? pagesAmount -1 : selectedPage - 1;
    setSelectedPage(newId);
  }

  const dots = Array(pagesAmount).fill(null).map((_, id) => (<CarouselDot key={id} selectedP={id === selectedPage} id={id} onClick={handleSelectSlidePage}/>))

  return (
    <div className="container flex w100 carousel-flex-gap">
      <div className="flex flex-space-between flex-align-center w100 carousel-flex-gap">
        <button className="clip-path-button carousel-left-arrow hover-bg-color-secondary-m bg-second-y " onClick={handleLeft} />
        { storedSlides.slice(...[0, slidesAmount]) }
        <button className="clip-path-button carousel-right-arrow hover-bg-color-secondary-m bg-second-y " onClick={handleRight} />
      </div>
      <div className="flex flex-align-center carousel-flex-gap">
        { dots }
      </div>
    </div>
  )
}

export default Carousel;
