import { CONTACT_EMAIL, CONTACT_EMAIL_LINK } from '../../';

export default function PrivacyPolicyHtml() {
  return (
    <>
      {/* saved from "https://app.termly.io/embed/terms-of-use/ef492468-c4c4-4fc6-b698-bb1d0c236060" */}
      <div id="hosted">
        <div
          style={{
            margin: "0px auto",
            fontFamily: "Roboto, Arial",
            padding: "50px 10px 100px"
          }}
        >
          <div data-custom-class="body">
            <div>
              <strong>
                <span style={{ fontSize: 26 }}>
                  <span data-custom-class="title">PRIVACY NOTICE</span>
                </span>
              </strong>
            </div>
            <div>
              <br />
            </div>
            <div>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="subtitle">
                      Last updated <bdt className="question">July 25, 2022</bdt>
                    </span>
                  </span>
                </strong>
              </span>
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    This privacy notice for{" "}
                    <bdt className="question">Tomb of the Mask NFT</bdt>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>{" "}
                    (<bdt className="block-component" />"
                    <bdt className="block-component" />
                    <strong>Company</strong>
                    <bdt className="statement-end-if-in-editor" />
                    ," "<strong>we</strong>," "<strong>us</strong>," or "
                    <strong>our</strong>"
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                  <span data-custom-class="body_text">
                    ), describes how and why we might collect, store, use, and/or
                    share (<bdt className="block-component" />"
                    <strong>process</strong>"
                    <bdt className="statement-end-if-in-editor" />) your information
                    when you use our services (<bdt className="block-component" />"
                    <strong>Services</strong>"
                    <bdt className="statement-end-if-in-editor" />
                    ), such as when you:
                  </span>
                </span>
              </span>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <div>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        Download and use
                        <bdt className="block-component" /> our mobile application
                        <bdt className="block-component" /> (
                        <bdt className="question">
                          Tomb of the Mask NFT)
                          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: 15 }}>
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                          <bdt className="statement-end-if-in-editor">
                                            ,
                                          </bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </bdt>
                      </span>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component">
                                      {" "}
                                      or any other application of ours that links to
                                      this privacy notice
                                    </bdt>
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        Engage with us in other related ways, including any sales,
                        marketing, or events
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                      <strong>Questions or concerns?&nbsp;</strong>Reading this
                      privacy notice will help you understand your privacy rights
                      and choices. If you do not agree with our policies and
                      practices, please do not use our Services. If you still have
                      any questions or concerns, please contaCONTACT_EMAILct us at{" "}
                      <bdt className="question">{CONTACT_EMAIL}</bdt>.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
                  </span>
                </strong>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>
                        This summary provides key points from our privacy notice,
                        but you can find out more details about any of these topics
                        by clicking the link following each key point or by using
                        our table of contents below to find the section you are
                        looking for. You can also click&nbsp;
                      </em>
                    </strong>
                  </span>
                </span>
                <a data-custom-class="link" href="#toc">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>here</em>
                      </strong>
                    </span>
                  </span>
                </a>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>&nbsp;to go directly to our table of contents.</em>
                    </strong>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>What personal information do we process?</strong> When
                    you visit, use, or navigate our Services, we may process
                    personal information depending on how you interact with{" "}
                    <bdt className="block-component" />
                    <bdt className="question">Tomb of the Mask NFT</bdt>
                    <bdt className="statement-end-if-in-editor" /> and the Services,
                    the choices you make, and the products and features you use.
                    Click&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#personalinfo">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">here</span>
                  </span>
                </a>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">&nbsp;to learn more.</span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>
                      Do we process any sensitive personal information?
                    </strong>{" "}
                    <bdt className="block-component" />
                    We do not process sensitive personal information.
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>
                      Do we receive any information from third parties?
                    </strong>{" "}
                    <bdt className="block-component" />
                    We do not receive any information from third parties.
                    <bdt className="else-block" />
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>How do we process your information?</strong> We process
                    your information to provide, improve, and administer our
                    Services, communicate with you, for security and fraud
                    prevention, and to comply with law. We may also process your
                    information for other purposes with your consent. We process
                    your information only when we have a valid legal reason to do
                    so. Click&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#infouse">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">here</span>
                  </span>
                </a>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">&nbsp;to learn more.</span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>
                      In what situations and with which{" "}
                      <bdt className="block-component" />
                      types of <bdt className="statement-end-if-in-editor" />
                      parties do we share personal information?
                    </strong>{" "}
                    We may share information in specific situations and with
                    specific <bdt className="block-component" />
                    categories of <bdt className="statement-end-if-in-editor" />
                    third parties. Click&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#whoshare">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">here</span>
                  </span>
                </a>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    &nbsp;to learn more.
                    <bdt className="block-component" />
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>How do we keep your information safe?</strong> We have{" "}
                    <bdt className="block-component" />
                    organizational
                    <bdt className="statement-end-if-in-editor" /> and technical
                    processes and procedures in place to protect your personal
                    information. However, no electronic transmission over the
                    internet or information storage technology can be guaranteed to
                    be 100% secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other <bdt className="block-component" />
                    unauthorized
                    <bdt className="statement-end-if-in-editor" /> third parties
                    will not be able to defeat our security and improperly collect,
                    access, steal, or modify your information. Click&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#infosafe">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">here</span>
                  </span>
                </a>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    &nbsp;to learn more.
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>What are your rights?</strong> Depending on where you
                    are located geographically, the applicable privacy law may mean
                    you have certain rights regarding your personal information.
                    Click&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#privacyrights">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">here</span>
                  </span>
                </a>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">&nbsp;to learn more.</span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>How do I exercise my rights?</strong> The easiest way to
                    exercise your rights is by filling out our data subject request
                    form available <bdt className="block-component" />
                    here:{" "}
                    <bdt className="question">{CONTACT_EMAIL_LINK}</bdt>
                    <bdt className="else-block" />, or by contacting us. We will
                    consider and act upon any request in accordance with applicable
                    data protection laws.
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    Want to learn more about what{" "}
                    <bdt className="block-component" />
                    <bdt className="question">Tomb of the Mask NFT</bdt>
                    <bdt className="statement-end-if-in-editor" /> does with any
                    information we collect? Click&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#toc">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">here</span>
                  </span>
                </a>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    &nbsp;to review the notice in full.
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div id="toc" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                      </strong>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#infocollect">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#infouse">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      2. HOW DO WE PROCESS YOUR INFORMATION?
                      <bdt className="block-component" />
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#legalbases">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      3.{" "}
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                          INFORMATION?
                        </span>
                      </span>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <a data-custom-class="link" href="#whoshare">
                      4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </a>
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#cookies">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </span>
                  </a>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#sociallogins">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </span>
                      </span>
                    </span>
                  </a>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </span>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#inforetain">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      7. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </span>
                  </a>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#infosafe">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </span>
                  </a>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#infominors">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      9. DO WE COLLECT INFORMATION FROM MINORS?
                    </span>
                  </a>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <a data-custom-class="link" href="#privacyrights">
                      10. WHAT ARE YOUR PRIVACY RIGHTS?
                    </a>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#DNT">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      11. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#caresidents">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <a data-custom-class="link" href="#policyupdates">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      13. DO WE MAKE UPDATES TO THIS NOTICE?
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <a data-custom-class="link" href="#contact">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </span>
                </a>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <a data-custom-class="link" href="#request">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </span>
                </a>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div id="infocollect" style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                          <strong>
                            <span data-custom-class="heading_1">
                              1. WHAT INFORMATION DO WE COLLECT?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div id="personalinfo" style={{ lineHeight: "1.5" }}>
                <span
                  data-custom-class="heading_2"
                  style={{ color: "rgb(0, 0, 0)" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Personal information you disclose to us</strong>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short:</em>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <strong>
                                <em>&nbsp;</em>
                              </strong>
                              <em>
                                We collect personal information that you provide to
                                us.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      We collect personal information that you voluntarily provide
                      to us when you{" "}
                      <span style={{ fontSize: 15 }}>
                        <bdt className="block-component" />
                      </span>
                      register on the Services,&nbsp;
                    </span>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        express an interest in obtaining information about us or our
                        products and Services, when you participate in activities on
                        the Services, or otherwise when you contact us.
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>Personal Information Provided by You.</strong> The
                      personal information that we collect depends on the context of
                      your interactions with us and the Services, the choices you
                      make, and the products and features you use. The personal
                      information we collect may include the following:
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <bdt className="question">email addresses</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <bdt className="question">usernames</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <bdt className="question">passwords</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component">
                            <bdt className="block-component" />
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>Social Media Login Data.&nbsp;</strong>We may provide
                      you with the option to register with us using your existing
                      social media account details, like your Facebook, Twitter, or
                      other social media account. If you choose to register in this
                      way, we will collect the information described in the section
                      called <bdt className="block-component" />"
                      <bdt className="statement-end-if-in-editor" />
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <a data-custom-class="link" href="#sociallogins">
                                HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                              </a>
                            </span>
                          </span>
                        </span>
                      </span>
                      <bdt className="block-component" />"
                      <bdt className="statement-end-if-in-editor" /> below.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <bdt className="statement-end-if-in-editor">
                          <bdt className="statement-end-if-in-editor" />
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="block-component">
                  {" "}
                  <bdt className="block-component" />
                </bdt>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <strong>Application Data.</strong> If you use our
                    application(s), we also may collect the following information if
                    you choose to provide us with access or permission:
                    <bdt className="block-component" />
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <em>Geolocation Information.</em> We may request access or
                      permission to track location-based information from your
                      mobile device, either continuously or while you are using our
                      mobile application(s), to provide certain location-based
                      services. If you wish to change our access or permissions, you
                      may do so in your device's settings.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <em>Mobile Device Access.</em> We may request access or
                      permission to certain features from your mobile device,
                      including your mobile device's{" "}
                      <bdt className="forloop-component" />
                      <bdt className="question">apple health</bdt>,{" "}
                      <bdt className="forloop-component" />
                      and other features. If you wish to change our access or
                      permissions, you may do so in your device's settings.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <em>Mobile Device Data.</em> We automatically collect device
                      information (such as your mobile device ID, model, and
                      manufacturer), operating system, version information and
                      system configuration information, device and application
                      identification numbers, browser type and version, hardware
                      model Internet service provider and/or mobile carrier, and
                      Internet Protocol (IP) address (or proxy server). If you are
                      using our application(s), we may also collect information
                      about the phone network associated with your mobile device,
                      your mobile device’s operating system or platform, the type of
                      mobile device you use, your mobile device’s unique device ID,
                      and information about the features of our application(s) you
                      accessed.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <em>Push Notifications.</em> We may request to send you push
                      notifications regarding your account or certain features of
                      the application(s). If you wish to opt out from receiving
                      these types of communications, you may turn them off in your
                      device's settings.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    This information is primarily needed to maintain the security
                    and operation of our application(s), for troubleshooting, and
                    for our internal analytics and reporting purposes.
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="statement-end-if-in-editor">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      All personal information that you provide to us must be true,
                      complete, and accurate, and you must notify us of any changes
                      to such personal information.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span
                  data-custom-class="heading_2"
                  style={{ color: "rgb(0, 0, 0)" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Information automatically collected</strong>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short:</em>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <strong>
                                <em>&nbsp;</em>
                              </strong>
                              <em>
                                Some information — such as your Internet Protocol
                                (IP) address and/or browser and device
                                characteristics — is collected automatically when
                                you visit our Services.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      We automatically collect certain information when you visit,
                      use, or navigate the Services. This information does not
                      reveal your specific identity (like your name or contact
                      information) but may include device and usage information,
                      such as your IP address, browser and device characteristics,
                      operating system, language preferences, referring URLs, device
                      name, country, location, information about how and when you
                      use our Services, and other technical information. This
                      information is primarily needed to maintain the security and
                      operation of our Services, and for our internal analytics and
                      reporting purposes.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      Like many businesses, we also collect information through
                      cookies and similar technologies.{" "}
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor">
                        <span data-custom-class="body_text" />
                      </bdt>
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      The information we collect includes:
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <em>Log and Usage Data.</em> Log and usage data is
                        service-related, diagnostic, usage, and performance
                        information our servers automatically collect when you
                        access or use our Services and which we record in log files.
                        Depending on how you interact with us, this log data may
                        include your IP address, device information, browser type,
                        and settings and information about your activity in the
                        Services<span style={{ fontSize: 15 }}>&nbsp;</span>(such as
                        the date/time stamps associated with your usage, pages and
                        files viewed, searches, and other actions you take such as
                        which features you use), device event information (such as
                        system activity, error reports (sometimes called{" "}
                        <bdt className="block-component" />
                        "crash dumps"
                        <bdt className="statement-end-if-in-editor" />
                        ), and hardware settings).
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <em>Device Data.</em> We collect device data such as
                        information about your computer, phone, tablet, or other
                        device you use to access the Services. Depending on the
                        device used, this device data may include information such
                        as your IP address (or proxy server), device and application
                        identification numbers, location, browser type, hardware
                        model, Internet service provider and/or mobile carrier,
                        operating system, and system configuration information.
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <em>Location Data.</em> We collect location data such as
                        information about your device's location, which can be
                        either precise or imprecise. How much information we collect
                        depends on the type and settings of the device you use to
                        access the Services. For example, we may use GPS and other
                        technologies to collect geolocation data that tells us your
                        current location (based on your IP address). You can opt out
                        of allowing us to collect this information either by
                        refusing access to the information or by disabling your
                        Location setting on your device. However, if you choose to
                        opt out, you may not be able to use certain aspects of the
                        Services.
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
                <bdt className="statement-end-if-in-editor" />
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor">
                            <bdt className="block-component" />
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </div>
              <div id="infouse" style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                          <strong>
                            <span data-custom-class="heading_1">
                              2. HOW DO WE PROCESS YOUR INFORMATION?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short:&nbsp;</em>
                              </strong>
                              <em>
                                We process your information to provide, improve, and
                                administer our Services, communicate with you, for
                                security and fraud prevention, and to comply with
                                law. We may also process your information for other
                                purposes with your consent.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our Services,
                        including:
                      </strong>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.&nbsp;
                        </strong>
                        We may process your information so you can create and log in
                        to your account, as well as keep your account in working
                        order.
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </div>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            To deliver and facilitate delivery of services to the
                            user.&nbsp;
                          </strong>
                          We may process your information to provide you with the
                          requested service.
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span
                                  style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span style={{ fontSize: 15 }}>
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{ fontSize: 15 }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: 15 }} />
                          </bdt>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </bdt>
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </bdt>
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <bdt className="block-component" />
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <bdt className="block-component" />
                            </p>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }} />
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                                    <span style={{ fontSize: 15 }} />
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <bdt className="block-component">
                                        <span data-custom-class="body_text" />
                                      </bdt>
                                    </span>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                  <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <div style={{ lineHeight: "1.5" }}>
                                                  <bdt className="block-component">
                                                    <span style={{ fontSize: 15 }}>
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <div
                                                    style={{ lineHeight: "1.5" }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <div
                                                      style={{ lineHeight: "1.5" }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{ fontSize: 15 }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <bdt className="block-component">
                                                          <span
                                                            style={{ fontSize: 15 }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                        </bdt>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <strong>
                                                                  To save or protect
                                                                  an individual's
                                                                  vital interest.
                                                                </strong>{" "}
                                                                We may process your
                                                                information when
                                                                necessary to save or
                                                                protect an
                                                                individual’s vital
                                                                interest, such as to
                                                                prevent harm.
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="legalbases"
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="heading_1">
                                                                3. WHAT LEGAL BASES
                                                                DO WE RELY ON TO
                                                                PROCESS YOUR
                                                                INFORMATION?
                                                              </span>
                                                            </span>
                                                          </strong>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <em>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  In Short:&nbsp;
                                                                </strong>
                                                                We only process your
                                                                personal information
                                                                when we believe it
                                                                is necessary and we
                                                                have a valid legal
                                                                reason (i.e.
                                                                <bdt className="block-component" />
                                                                ,
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                legal basis) to do
                                                                so under applicable
                                                                law, like with your
                                                                consent, to comply
                                                                with laws, to
                                                                provide you with
                                                                services to enter
                                                                into or{" "}
                                                                <bdt className="block-component" />
                                                                fulfill
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                our contractual
                                                                obligations, to
                                                                protect your rights,
                                                                or to{" "}
                                                                <bdt className="block-component" />
                                                                fulfill
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                our legitimate
                                                                business interests.
                                                              </span>
                                                            </span>
                                                          </em>
                                                          <span
                                                            style={{ fontSize: 15 }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <em>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <u>
                                                                    If you are
                                                                    located in the
                                                                    EU or UK, this
                                                                    section applies
                                                                    to you.
                                                                  </u>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </em>
                                                          <span
                                                            style={{ fontSize: 15 }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{ fontSize: 15 }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              The General Data
                                                              Protection Regulation
                                                              (GDPR) and UK GDPR
                                                              require us to explain
                                                              the valid legal bases
                                                              we rely on in order to
                                                              process your personal
                                                              information. As such,
                                                              we may rely on the
                                                              following legal bases
                                                              to process your
                                                              personal information:
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Consent.&nbsp;
                                                                </strong>
                                                                We may process your
                                                                information if you
                                                                have given us
                                                                permission (i.e.
                                                                <bdt className="block-component" />
                                                                ,
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                consent) to use your
                                                                personal information
                                                                for a specific
                                                                purpose. You can
                                                                withdraw your
                                                                consent at any time.
                                                                Click&nbsp;
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="#withdrawconsent"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  here
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                &nbsp;to learn more.
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <strong>
                                                                  Performance of a
                                                                  Contract.
                                                                </strong>{" "}
                                                                We may process your
                                                                personal information
                                                                when we believe it
                                                                is necessary to{" "}
                                                                <bdt className="block-component" />
                                                                fulfill
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                our contractual
                                                                obligations to you,
                                                                including providing
                                                                our Services or at
                                                                your request prior
                                                                to entering into a
                                                                contract with you.
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <strong>
                                                                  Legal Obligations.
                                                                </strong>{" "}
                                                                We may process your
                                                                information where we
                                                                believe it is
                                                                necessary for
                                                                compliance with our
                                                                legal obligations,
                                                                such as to cooperate
                                                                with a law
                                                                enforcement body or
                                                                regulatory agency,
                                                                exercise or defend
                                                                our legal rights, or
                                                                disclose your
                                                                information as
                                                                evidence in
                                                                litigation in which
                                                                we are involved.
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <br />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <strong>
                                                                  Vital Interests.
                                                                </strong>{" "}
                                                                We may process your
                                                                information where we
                                                                believe it is
                                                                necessary to protect
                                                                your vital interests
                                                                or the vital
                                                                interests of a third
                                                                party, such as
                                                                situations involving
                                                                potential threats to
                                                                the safety of any
                                                                person.
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <strong>
                                                                <u>
                                                                  <em>
                                                                    If you are
                                                                    located in
                                                                    Canada, this
                                                                    section applies
                                                                    to you.
                                                                  </em>
                                                                </u>
                                                              </strong>
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              We may process your
                                                              information if you
                                                              have given us specific
                                                              permission (i.e.
                                                              <bdt className="block-component" />
                                                              ,
                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                              express consent) to
                                                              use your personal
                                                              information for a
                                                              specific purpose, or
                                                              in situations where
                                                              your permission can be
                                                              inferred (i.e.
                                                              <bdt className="block-component" />
                                                              ,
                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                              implied consent). You
                                                              can withdraw your
                                                              consent at any time.
                                                              Click&nbsp;
                                                            </span>
                                                          </span>
                                                          <a
                                                            data-custom-class="link"
                                                            href="#withdrawconsent"
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                here
                                                              </span>
                                                            </span>
                                                          </a>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              &nbsp;to learn more.
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              In some exceptional
                                                              cases, we may be
                                                              legally permitted
                                                              under applicable law
                                                              to process your
                                                              information without
                                                              your consent,
                                                              including, for
                                                              example:
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If collection is
                                                                clearly in the
                                                                interests of an
                                                                individual and
                                                                consent cannot be
                                                                obtained in a timely
                                                                way
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                For investigations
                                                                and fraud detection
                                                                and prevention
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                For business
                                                                transactions
                                                                provided certain
                                                                conditions are met
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If it is contained
                                                                in a witness
                                                                statement and the
                                                                collection is
                                                                necessary to assess,
                                                                process, or settle
                                                                an insurance claim
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                For identifying
                                                                injured, ill, or
                                                                deceased persons and
                                                                communicating with
                                                                next of kin
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If we have
                                                                reasonable grounds
                                                                to believe an
                                                                individual has been,
                                                                is, or may be victim
                                                                of financial abuse
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If it is reasonable
                                                                to expect collection
                                                                and use with consent
                                                                would compromise the
                                                                availability or the
                                                                accuracy of the
                                                                information and the
                                                                collection is
                                                                reasonable for
                                                                purposes related to
                                                                investigating a
                                                                breach of an
                                                                agreement or a
                                                                contravention of the
                                                                laws of Canada or a
                                                                province
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If disclosure is
                                                                required to comply
                                                                with a subpoena,
                                                                warrant, court
                                                                order, or rules of
                                                                the court relating
                                                                to the production of
                                                                records
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If it was produced
                                                                by an individual in
                                                                the course of their
                                                                employment,
                                                                business, or
                                                                profession and the
                                                                collection is
                                                                consistent with the
                                                                purposes for which
                                                                the information was
                                                                produced
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{ fontSize: 15 }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If the collection is
                                                                solely for
                                                                journalistic,
                                                                artistic, or
                                                                literary purposes
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{ fontSize: 15 }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If the information
                                                                is publicly
                                                                available and is
                                                                specified by the
                                                                regulations
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span data-custom-class="body_text" />
                                                              </bdt>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="whoshare"
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    id="control"
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)"
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span data-custom-class="heading_1">
                                                                        4. WHEN AND
                                                                        WITH WHOM DO
                                                                        WE SHARE
                                                                        YOUR
                                                                        PERSONAL
                                                                        INFORMATION?
                                                                      </span>
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <em>In Short:</em>
                                                                </strong>
                                                                <em>
                                                                  &nbsp;We may share
                                                                  information in
                                                                  specific
                                                                  situations
                                                                  described in this
                                                                  section and/or
                                                                  with the following{" "}
                                                                  <bdt className="block-component" />
                                                                  categories of{" "}
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                  third parties.
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{ fontSize: 15 }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                Vendors,
                                                                Consultants, and
                                                                Other Third-Party
                                                                Service Providers.
                                                              </strong>{" "}
                                                              We may share your data
                                                              with third-party
                                                              vendors, service
                                                              providers,
                                                              contractors, or agents
                                                              (
                                                              <bdt className="block-component" />
                                                              "
                                                              <strong>
                                                                third parties
                                                              </strong>
                                                              "
                                                              <bdt className="statement-end-if-in-editor" />
                                                              ) who perform services
                                                              for us or on our
                                                              behalf and require
                                                              access to such
                                                              information to do that
                                                              work.{" "}
                                                              <bdt className="block-component" />
                                                              The{" "}
                                                              <bdt className="block-component" />
                                                              categories of{" "}
                                                              <bdt className="statement-end-if-in-editor" />
                                                              third parties we may
                                                              share personal
                                                              information with are
                                                              as follows:
                                                            </span>
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="question">
                                                                    Data Analytics
                                                                    Services
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="forloop-component">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{ fontSize: 15 }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              We{" "}
                                                              <bdt className="block-component" />
                                                              also{" "}
                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                              may need to share your
                                                              personal information
                                                              in the following
                                                              situations:
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Business
                                                                  Transfers.
                                                                </strong>{" "}
                                                                We may share or
                                                                transfer your
                                                                information in
                                                                connection with, or
                                                                during negotiations
                                                                of, any merger, sale
                                                                of company assets,
                                                                financing, or
                                                                acquisition of all
                                                                or a portion of our
                                                                business to another
                                                                company.
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{ fontSize: 15 }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span data-custom-class="body_text" />
                                                              </bdt>
                                                            </span>
                                                            <div
                                                              style={{
                                                                lineHeight: "1.5"
                                                              }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                            </div>
                                                            <ul>
                                                              <li
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      Business
                                                                      Partners.
                                                                    </strong>{" "}
                                                                    We may share
                                                                    your information
                                                                    with our
                                                                    business
                                                                    partners to
                                                                    offer you
                                                                    certain
                                                                    products,
                                                                    services, or
                                                                    promotions.
                                                                  </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                              </li>
                                                            </ul>
                                                            <div
                                                              style={{
                                                                lineHeight: "1.5"
                                                              }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        Offer
                                                                        Wall.&nbsp;
                                                                      </strong>
                                                                      Our
                                                                      application(s)
                                                                      may display a
                                                                      third-party
                                                                      hosted{" "}
                                                                      <bdt className="block-component" />
                                                                      "offer wall."
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      Such an offer
                                                                      wall allows
                                                                      third-party
                                                                      advertisers to
                                                                      offer virtual
                                                                      currency,
                                                                      gifts, or
                                                                      other items to
                                                                      users in
                                                                      return for the
                                                                      acceptance and
                                                                      completion of
                                                                      an
                                                                      advertisement
                                                                      offer. Such an
                                                                      offer wall may
                                                                      appear in our
                                                                      application(s)
                                                                      and be
                                                                      displayed to
                                                                      you based on
                                                                      certain data,
                                                                      such as your
                                                                      geographic
                                                                      area or
                                                                      demographic
                                                                      information.
                                                                      When you click
                                                                      on an offer
                                                                      wall, you will
                                                                      be brought to
                                                                      an external
                                                                      website
                                                                      belonging to
                                                                      other persons
                                                                      and will leave
                                                                      our
                                                                      application(s).
                                                                      A unique
                                                                      identifier,
                                                                      such as your
                                                                      user ID, will
                                                                      be shared with
                                                                      the offer wall
                                                                      provider in
                                                                      order to
                                                                      prevent fraud
                                                                      and properly
                                                                      credit your
                                                                      account with
                                                                      the relevant
                                                                      reward.
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="statement-end-if-in-editor">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </bdt>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component">
                                                                            <span data-custom-class="heading_1" />
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="cookies"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              5. DO
                                                                              WE USE
                                                                              COOKIES
                                                                              AND
                                                                              OTHER
                                                                              TRACKING
                                                                              TECHNOLOGIES?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In Short:
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        &nbsp;We may
                                                                        use cookies
                                                                        and other
                                                                        tracking
                                                                        technologies
                                                                        to collect
                                                                        and store
                                                                        your
                                                                        information.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We may use
                                                                      cookies and
                                                                      similar
                                                                      tracking
                                                                      technologies
                                                                      (like web
                                                                      beacons and
                                                                      pixels) to
                                                                      access or
                                                                      store
                                                                      information.
                                                                      Specific
                                                                      information
                                                                      about how we
                                                                      use such
                                                                      technologies
                                                                      and how you
                                                                      can refuse
                                                                      certain
                                                                      cookies is set
                                                                      out in our
                                                                      Cookie Notice
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          .
                                                                        </span>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="sociallogins"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              6. HOW
                                                                              DO WE
                                                                              HANDLE
                                                                              YOUR
                                                                              SOCIAL
                                                                              LOGINS?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:&nbsp;
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        If you
                                                                        choose to
                                                                        register or
                                                                        log in to
                                                                        our services
                                                                        using a
                                                                        social media
                                                                        account, we
                                                                        may have
                                                                        access to
                                                                        certain
                                                                        information
                                                                        about you.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      Our Services
                                                                      offer you the
                                                                      ability to
                                                                      register and
                                                                      log in using
                                                                      your
                                                                      third-party
                                                                      social media
                                                                      account
                                                                      details (like
                                                                      your Facebook
                                                                      or Twitter
                                                                      logins). Where
                                                                      you choose to
                                                                      do this, we
                                                                      will receive
                                                                      certain
                                                                      profile
                                                                      information
                                                                      about you from
                                                                      your social
                                                                      media
                                                                      provider. The
                                                                      profile
                                                                      information we
                                                                      receive may
                                                                      vary depending
                                                                      on the social
                                                                      media provider
                                                                      concerned, but
                                                                      will often
                                                                      include your
                                                                      name, email
                                                                      address,
                                                                      friends list,
                                                                      and profile
                                                                      picture, as
                                                                      well as other
                                                                      information
                                                                      you choose to
                                                                      make public on
                                                                      such a social
                                                                      media
                                                                      platform.{" "}
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We will use
                                                                      the
                                                                      information we
                                                                      receive only
                                                                      for the
                                                                      purposes that
                                                                      are described
                                                                      in this
                                                                      privacy notice
                                                                      or that are
                                                                      otherwise made
                                                                      clear to you
                                                                      on the
                                                                      relevant
                                                                      Services.
                                                                      Please note
                                                                      that we do not
                                                                      control, and
                                                                      are not
                                                                      responsible
                                                                      for, other
                                                                      uses of your
                                                                      personal
                                                                      information by
                                                                      your
                                                                      third-party
                                                                      social media
                                                                      provider. We
                                                                      recommend that
                                                                      you review
                                                                      their privacy
                                                                      notice to
                                                                      understand how
                                                                      they collect,
                                                                      use, and share
                                                                      your personal
                                                                      information,
                                                                      and how you
                                                                      can set your
                                                                      privacy
                                                                      preferences on
                                                                      their sites
                                                                      and apps.
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                      <bdt className="block-component">
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="block-component" />
                                                                                        </span>
                                                                                      </bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="inforetain"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              7. HOW
                                                                              LONG
                                                                              DO WE
                                                                              KEEP
                                                                              YOUR
                                                                              INFORMATION?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:&nbsp;
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        We keep your
                                                                        information
                                                                        for as long
                                                                        as necessary
                                                                        to{" "}
                                                                        <bdt className="block-component" />
                                                                        fulfill
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        the purposes
                                                                        outlined in
                                                                        this privacy
                                                                        notice
                                                                        unless
                                                                        otherwise
                                                                        required by
                                                                        law.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We will only
                                                                      keep your
                                                                      personal
                                                                      information
                                                                      for as long as
                                                                      it is
                                                                      necessary for
                                                                      the purposes
                                                                      set out in
                                                                      this privacy
                                                                      notice, unless
                                                                      a longer
                                                                      retention
                                                                      period is
                                                                      required or
                                                                      permitted by
                                                                      law (such as
                                                                      tax,
                                                                      accounting, or
                                                                      other legal
                                                                      requirements).
                                                                      No purpose in
                                                                      this notice
                                                                      will require
                                                                      us keeping
                                                                      your personal
                                                                      information
                                                                      for longer
                                                                      than{" "}
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="block-component" />
                                                                      the period of
                                                                      time in which
                                                                      users have an
                                                                      account with
                                                                      us
                                                                      <bdt className="block-component" />
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="else-block" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      When we have
                                                                      no ongoing
                                                                      legitimate
                                                                      business need
                                                                      to process
                                                                      your personal
                                                                      information,
                                                                      we will either
                                                                      delete or{" "}
                                                                      <bdt className="block-component" />
                                                                      anonymize
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      such
                                                                      information,
                                                                      or, if this is
                                                                      not possible
                                                                      (for example,
                                                                      because your
                                                                      personal
                                                                      information
                                                                      has been
                                                                      stored in
                                                                      backup
                                                                      archives),
                                                                      then we will
                                                                      securely store
                                                                      your personal
                                                                      information
                                                                      and isolate it
                                                                      from any
                                                                      further
                                                                      processing
                                                                      until deletion
                                                                      is possible.
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="infosafe"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              8. HOW
                                                                              DO WE
                                                                              KEEP
                                                                              YOUR
                                                                              INFORMATION
                                                                              SAFE?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:&nbsp;
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        We aim to
                                                                        protect your
                                                                        personal
                                                                        information
                                                                        through a
                                                                        system of{" "}
                                                                        <bdt className="block-component" />
                                                                        organizational
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        and
                                                                        technical
                                                                        security
                                                                        measures.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We have
                                                                      implemented
                                                                      appropriate
                                                                      and reasonable
                                                                      technical and{" "}
                                                                      <bdt className="block-component" />
                                                                      organizational
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      security
                                                                      measures
                                                                      designed to
                                                                      protect the
                                                                      security of
                                                                      any personal
                                                                      information we
                                                                      process.
                                                                      However,
                                                                      despite our
                                                                      safeguards and
                                                                      efforts to
                                                                      secure your
                                                                      information,
                                                                      no electronic
                                                                      transmission
                                                                      over the
                                                                      Internet or
                                                                      information
                                                                      storage
                                                                      technology can
                                                                      be guaranteed
                                                                      to be 100%
                                                                      secure, so we
                                                                      cannot promise
                                                                      or guarantee
                                                                      that hackers,
                                                                      cybercriminals,
                                                                      or other{" "}
                                                                      <bdt className="block-component" />
                                                                      unauthorized
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      third parties
                                                                      will not be
                                                                      able to defeat
                                                                      our security
                                                                      and improperly
                                                                      collect,
                                                                      access, steal,
                                                                      or modify your
                                                                      information.
                                                                      Although we
                                                                      will do our
                                                                      best to
                                                                      protect your
                                                                      personal
                                                                      information,
                                                                      transmission
                                                                      of personal
                                                                      information to
                                                                      and from our
                                                                      Services is at
                                                                      your own risk.
                                                                      You should
                                                                      only access
                                                                      the Services
                                                                      within a
                                                                      secure
                                                                      environment.
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="infominors"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              9. DO
                                                                              WE
                                                                              COLLECT
                                                                              INFORMATION
                                                                              FROM
                                                                              MINORS?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In Short:
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        &nbsp;We do
                                                                        not
                                                                        knowingly
                                                                        collect data
                                                                        from or
                                                                        market to
                                                                        children
                                                                        under 18
                                                                        years of
                                                                        age.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We do not
                                                                      knowingly
                                                                      solicit data
                                                                      from or market
                                                                      to children
                                                                      under 18 years
                                                                      of age. By
                                                                      using the
                                                                      Services, you
                                                                      represent that
                                                                      you are at
                                                                      least 18 or
                                                                      that you are
                                                                      the parent or
                                                                      guardian of
                                                                      such a minor
                                                                      and consent to
                                                                      such minor
                                                                      dependent’s
                                                                      use of the
                                                                      Services. If
                                                                      we learn that
                                                                      personal
                                                                      information
                                                                      from users
                                                                      less than 18
                                                                      years of age
                                                                      has been
                                                                      collected, we
                                                                      will
                                                                      deactivate the
                                                                      account and
                                                                      take
                                                                      reasonable
                                                                      measures to
                                                                      promptly
                                                                      delete such
                                                                      data from our
                                                                      records. If
                                                                      you become
                                                                      aware of any
                                                                      data we may
                                                                      have collected
                                                                      from children
                                                                      under age 18,
                                                                      please contact
                                                                      us at{" "}
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          <bdt className="question">{CONTACT_EMAIL}</bdt>
                                                                          <bdt className="else-block" />
                                                                        </span>
                                                                      </span>
                                                                      .
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="privacyrights"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              10.
                                                                              WHAT
                                                                              ARE
                                                                              YOUR
                                                                              PRIVACY
                                                                              RIGHTS?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In Short:
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        &nbsp;
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <em>
                                                                                <bdt className="block-component" />
                                                                              </em>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        In some
                                                                        regions,
                                                                        such as{" "}
                                                                        <bdt className="block-component" />
                                                                        the European
                                                                        Economic
                                                                        Area (EEA),
                                                                        United
                                                                        Kingdom
                                                                        (UK), and
                                                                        Canada
                                                                        <bdt className="block-component" />
                                                                        , you have
                                                                        rights that
                                                                        allow you
                                                                        greater
                                                                        access to
                                                                        and control
                                                                        over your
                                                                        personal
                                                                        information.
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <em>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                              </em>
                                                                            </span>
                                                                          </span>
                                                                          &nbsp;
                                                                        </span>
                                                                        You may
                                                                        review,
                                                                        change, or
                                                                        terminate
                                                                        your account
                                                                        at any time.
                                                                      </em>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      In some
                                                                      regions (like{" "}
                                                                      <bdt className="block-component" />
                                                                      the EEA, UK,
                                                                      and Canada
                                                                      <bdt className="block-component" />
                                                                      ), you have
                                                                      certain rights
                                                                      under
                                                                      applicable
                                                                      data
                                                                      protection
                                                                      laws. These
                                                                      may include
                                                                      the right (i)
                                                                      to request
                                                                      access and
                                                                      obtain a copy
                                                                      of your
                                                                      personal
                                                                      information,
                                                                      (ii) to
                                                                      request
                                                                      rectification
                                                                      or erasure;
                                                                      (iii) to
                                                                      restrict the
                                                                      processing of
                                                                      your personal
                                                                      information;
                                                                      and (iv) if
                                                                      applicable, to
                                                                      data
                                                                      portability.
                                                                      In certain
                                                                      circumstances,
                                                                      you may also
                                                                      have the right
                                                                      to object to
                                                                      the processing
                                                                      of your
                                                                      personal
                                                                      information.
                                                                      You can make
                                                                      such a request
                                                                      by contacting
                                                                      us by using
                                                                      the contact
                                                                      details
                                                                      provided in
                                                                      the section{" "}
                                                                      <bdt className="block-component" />
                                                                      "
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="#contact"
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        HOW CAN YOU
                                                                        CONTACT US
                                                                        ABOUT THIS
                                                                        NOTICE?
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </a>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                      "
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      below.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We will
                                                                      consider and
                                                                      act upon any
                                                                      request in
                                                                      accordance
                                                                      with
                                                                      applicable
                                                                      data
                                                                      protection
                                                                      laws.
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  &nbsp;
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      If you are
                                                                      located in the
                                                                      EEA or UK and
                                                                      you believe we
                                                                      are unlawfully
                                                                      processing
                                                                      your personal
                                                                      information,
                                                                      you also have
                                                                      the right to
                                                                      complain to
                                                                      your local
                                                                      data
                                                                      protection
                                                                      supervisory
                                                                      authority. You
                                                                      can find their
                                                                      contact
                                                                      details here:{" "}
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(48, 48, 241)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <a
                                                                                  data-custom-class="link"
                                                                                  href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                  rel="noopener noreferrer"
                                                                                  target="_blank"
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                                  </span>
                                                                                </a>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      If you are
                                                                      located in
                                                                      Switzerland,
                                                                      the contact
                                                                      details for
                                                                      the data
                                                                      protection
                                                                      authorities
                                                                      are available
                                                                      here:{" "}
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(48, 48, 241)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <a
                                                                                    data-custom-class="link"
                                                                                    href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                    rel="noopener noreferrer"
                                                                                    target="_blank"
                                                                                  >
                                                                                    https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                                  </a>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="withdrawconsent"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <u>
                                                                          Withdrawing
                                                                          your
                                                                          consent:
                                                                        </u>
                                                                      </strong>{" "}
                                                                      If we are
                                                                      relying on
                                                                      your consent
                                                                      to process
                                                                      your personal
                                                                      information,
                                                                      <bdt className="block-component" />{" "}
                                                                      which may be
                                                                      express and/or
                                                                      implied
                                                                      consent
                                                                      depending on
                                                                      the applicable
                                                                      law,
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      you have the
                                                                      right to
                                                                      withdraw your
                                                                      consent at any
                                                                      time. You can
                                                                      withdraw your
                                                                      consent at any
                                                                      time by
                                                                      contacting us
                                                                      by using the
                                                                      contact
                                                                      details
                                                                      provided in
                                                                      the section{" "}
                                                                      <bdt className="block-component" />
                                                                      "
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="#contact"
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        HOW CAN YOU
                                                                        CONTACT US
                                                                        ABOUT THIS
                                                                        NOTICE?
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </a>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                      "
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      below
                                                                      <bdt className="block-component" />
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    However, please
                                                                    note that this
                                                                    will not affect
                                                                    the lawfulness
                                                                    of the
                                                                    processing
                                                                    before its
                                                                    withdrawal, nor
                                                                    <bdt className="block-component" />{" "}
                                                                    when applicable
                                                                    law allows,
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    will it affect
                                                                    the processing
                                                                    of your personal
                                                                    information
                                                                    conducted in
                                                                    reliance on
                                                                    lawful
                                                                    processing
                                                                    grounds other
                                                                    than consent.
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      <u>
                                                                        Opting out
                                                                        of marketing
                                                                        and
                                                                        promotional
                                                                        communications:
                                                                      </u>
                                                                    </strong>
                                                                    <strong>
                                                                      <u>&nbsp;</u>
                                                                    </strong>
                                                                    You can
                                                                    unsubscribe from
                                                                    our marketing
                                                                    and promotional
                                                                    communications
                                                                    at any time by
                                                                    <bdt className="block-component" />{" "}
                                                                    clicking on the
                                                                    unsubscribe link
                                                                    in the emails
                                                                    that we send,
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />{" "}
                                                                    or by contacting
                                                                    us using the
                                                                    details provided
                                                                    in the section{" "}
                                                                    <bdt className="block-component" />
                                                                    "
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </span>
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="#contact"
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      HOW CAN YOU
                                                                      CONTACT US
                                                                      ABOUT THIS
                                                                      NOTICE?
                                                                    </span>
                                                                  </span>
                                                                </a>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                    "
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    below. You will
                                                                    then be removed
                                                                    from the
                                                                    marketing lists.
                                                                    However, we may
                                                                    still
                                                                    communicate with
                                                                    you — for
                                                                    example, to send
                                                                    you
                                                                    service-related
                                                                    messages that
                                                                    are necessary
                                                                    for the
                                                                    administration
                                                                    and use of your
                                                                    account, to
                                                                    respond to
                                                                    service
                                                                    requests, or for
                                                                    other
                                                                    non-marketing
                                                                    purposes.
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="heading_2">
                                                                    <strong>
                                                                      Account
                                                                      Information
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    If you would at
                                                                    any time like to
                                                                    review or change
                                                                    the information
                                                                    in your account
                                                                    or terminate
                                                                    your account,
                                                                    you can:
                                                                    <bdt className="forloop-component" />
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <bdt className="question">
                                                                        Contact us
                                                                        using the
                                                                        contact
                                                                        information
                                                                        provided.
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <bdt className="forloop-component" />
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    Upon your
                                                                    request to
                                                                    terminate your
                                                                    account, we will
                                                                    deactivate or
                                                                    delete your
                                                                    account and
                                                                    information from
                                                                    our active
                                                                    databases.
                                                                    However, we may
                                                                    retain some
                                                                    information in
                                                                    our files to
                                                                    prevent fraud,
                                                                    troubleshoot
                                                                    problems, assist
                                                                    with any
                                                                    investigations,
                                                                    enforce our
                                                                    legal terms
                                                                    and/or comply
                                                                    with applicable
                                                                    legal
                                                                    requirements.
                                                                  </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                  >
                                                                                    <bdt className="block-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <u>
                                                                          Cookies
                                                                          and
                                                                          similar
                                                                          technologies:
                                                                        </u>
                                                                      </strong>{" "}
                                                                      Most Web
                                                                      browsers are
                                                                      set to accept
                                                                      cookies by
                                                                      default. If
                                                                      you prefer,
                                                                      you can
                                                                      usually choose
                                                                      to set your
                                                                      browser to
                                                                      remove cookies
                                                                      and to reject
                                                                      cookies. If
                                                                      you choose to
                                                                      remove cookies
                                                                      or reject
                                                                      cookies, this
                                                                      could affect
                                                                      certain
                                                                      features or
                                                                      services of
                                                                      our Services.
                                                                      To opt out of
                                                                      interest-based
                                                                      advertising by
                                                                      advertisers on
                                                                      our Services
                                                                      visit{" "}
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(48, 48, 241)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <a
                                                                            data-custom-class="link"
                                                                            href="http://www.aboutads.info/choices/"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              http://www.aboutads.info/choices/
                                                                            </span>
                                                                          </a>
                                                                        </span>
                                                                      </span>
                                                                      .{" "}
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="DNT"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              11.
                                                                              CONTROLS
                                                                              FOR
                                                                              DO-NOT-TRACK
                                                                              FEATURES
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      Most web
                                                                      browsers and
                                                                      some mobile
                                                                      operating
                                                                      systems and
                                                                      mobile
                                                                      applications
                                                                      include a
                                                                      Do-Not-Track (
                                                                      <bdt className="block-component" />
                                                                      "DNT"
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                      ) feature or
                                                                      setting you
                                                                      can activate
                                                                      to signal your
                                                                      privacy
                                                                      preference not
                                                                      to have data
                                                                      about your
                                                                      online
                                                                      browsing
                                                                      activities
                                                                      monitored and
                                                                      collected. At
                                                                      this stage no
                                                                      uniform
                                                                      technology
                                                                      standard for{" "}
                                                                      <bdt className="block-component" />
                                                                      recognizing
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      and
                                                                      implementing
                                                                      DNT signals
                                                                      has been{" "}
                                                                      <bdt className="block-component" />
                                                                      finalized
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                      . As such, we
                                                                      do not
                                                                      currently
                                                                      respond to DNT
                                                                      browser
                                                                      signals or any
                                                                      other
                                                                      mechanism that
                                                                      automatically
                                                                      communicates
                                                                      your choice
                                                                      not to be
                                                                      tracked
                                                                      online. If a
                                                                      standard for
                                                                      online
                                                                      tracking is
                                                                      adopted that
                                                                      we must follow
                                                                      in the future,
                                                                      we will inform
                                                                      you about that
                                                                      practice in a
                                                                      revised
                                                                      version of
                                                                      this privacy
                                                                      notice.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="caresidents"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              12. DO
                                                                              CALIFORNIA
                                                                              RESIDENTS
                                                                              HAVE
                                                                              SPECIFIC
                                                                              PRIVACY
                                                                              RIGHTS?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:&nbsp;
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        Yes, if you
                                                                        are a
                                                                        resident of
                                                                        California,
                                                                        you are
                                                                        granted
                                                                        specific
                                                                        rights
                                                                        regarding
                                                                        access to
                                                                        your
                                                                        personal
                                                                        information.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      California
                                                                      Civil Code
                                                                      Section
                                                                      1798.83, also
                                                                      known as the{" "}
                                                                      <bdt className="block-component" />
                                                                      "Shine The
                                                                      Light"
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      law, permits
                                                                      our users who
                                                                      are California
                                                                      residents to
                                                                      request and
                                                                      obtain from
                                                                      us, once a
                                                                      year and free
                                                                      of charge,
                                                                      information
                                                                      about
                                                                      categories of
                                                                      personal
                                                                      information
                                                                      (if any) we
                                                                      disclosed to
                                                                      third parties
                                                                      for direct
                                                                      marketing
                                                                      purposes and
                                                                      the names and
                                                                      addresses of
                                                                      all third
                                                                      parties with
                                                                      which we
                                                                      shared
                                                                      personal
                                                                      information in
                                                                      the
                                                                      immediately
                                                                      preceding
                                                                      calendar year.
                                                                      If you are a
                                                                      California
                                                                      resident and
                                                                      would like to
                                                                      make such a
                                                                      request,
                                                                      please submit
                                                                      your request
                                                                      in writing to
                                                                      us using the
                                                                      contact
                                                                      information
                                                                      provided
                                                                      below.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      If you are
                                                                      under 18 years
                                                                      of age, reside
                                                                      in California,
                                                                      and have a
                                                                      registered
                                                                      account with
                                                                      Services, you
                                                                      have the right
                                                                      to request
                                                                      removal of
                                                                      unwanted data
                                                                      that you
                                                                      publicly post
                                                                      on the
                                                                      Services. To
                                                                      request
                                                                      removal of
                                                                      such data,
                                                                      please contact
                                                                      us using the
                                                                      contact
                                                                      information
                                                                      provided below
                                                                      and include
                                                                      the email
                                                                      address
                                                                      associated
                                                                      with your
                                                                      account and a
                                                                      statement that
                                                                      you reside in
                                                                      California. We
                                                                      will make sure
                                                                      the data is
                                                                      not publicly
                                                                      displayed on
                                                                      the Services,
                                                                      but please be
                                                                      aware that the
                                                                      data may not
                                                                      be completely
                                                                      or
                                                                      comprehensively
                                                                      removed from
                                                                      all our
                                                                      systems (e.g.
                                                                      <bdt className="block-component" />
                                                                      ,
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      backups,
                                                                      etc.).
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="policyupdates"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              13. DO
                                                                              WE
                                                                              MAKE
                                                                              UPDATES
                                                                              TO
                                                                              THIS
                                                                              NOTICE?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <em>
                                                                        <strong>
                                                                          In
                                                                          Short:&nbsp;
                                                                        </strong>
                                                                        Yes, we will
                                                                        update this
                                                                        notice as
                                                                        necessary to
                                                                        stay
                                                                        compliant
                                                                        with
                                                                        relevant
                                                                        laws.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We may update
                                                                      this privacy
                                                                      notice from
                                                                      time to time.
                                                                      The updated
                                                                      version will
                                                                      be indicated
                                                                      by an updated{" "}
                                                                      <bdt className="block-component" />
                                                                      "Revised"
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      date and the
                                                                      updated
                                                                      version will
                                                                      be effective
                                                                      as soon as it
                                                                      is accessible.
                                                                      If we make
                                                                      material
                                                                      changes to
                                                                      this privacy
                                                                      notice, we may
                                                                      notify you
                                                                      either by
                                                                      prominently
                                                                      posting a
                                                                      notice of such
                                                                      changes or by
                                                                      directly
                                                                      sending you a
                                                                      notification.
                                                                      We encourage
                                                                      you to review
                                                                      this privacy
                                                                      notice
                                                                      frequently to
                                                                      be informed of
                                                                      how we are
                                                                      protecting
                                                                      your
                                                                      information.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="contact"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              14.
                                                                              HOW
                                                                              CAN
                                                                              YOU
                                                                              CONTACT
                                                                              US
                                                                              ABOUT
                                                                              THIS
                                                                              NOTICE?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      You can contact us by email {CONTACT_EMAIL}
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="request"
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              15.
                                                                              HOW
                                                                              CAN
                                                                              YOU
                                                                              REVIEW,
                                                                              UPDATE,
                                                                              OR
                                                                              DELETE
                                                                              THE
                                                                              DATA
                                                                              WE
                                                                              COLLECT
                                                                              FROM
                                                                              YOU?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      Based on the
                                                                      applicable
                                                                      laws of your
                                                                      country, you
                                                                      may have the
                                                                      right to
                                                                      request access
                                                                      to the
                                                                      personal
                                                                      information we
                                                                      collect from
                                                                      you, change
                                                                      that
                                                                      information,
                                                                      or delete it
                                                                      in some
                                                                      circumstances.
                                                                      To request to
                                                                      review,
                                                                      update, or
                                                                      delete your
                                                                      personal
                                                                      information,
                                                                      please{" "}
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      contact us by email:{" "}
                                                                      <bdt className="question">{CONTACT_EMAIL}</bdt>
                                                                      <bdt className="else-block" />
                                                                    </span>
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    .
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <noscript>Your browser does not support JavaScript!</noscript>
    </>
  )
}
