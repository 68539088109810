import { COMPANY_NAME } from '../..';
import './styles.scss';

type TProps = {
  companyAboutLink?: string;
}

const Trademark = function(props: TProps) {
  const { companyAboutLink } = props;
  const linkText = COMPANY_NAME;

  return (
    <div className="trademark flex flex-align-center flex-row">
      <p className="trademark__text-copyright">© 2022</p>
      <a className="hover-color-secondary-m text-primary" href={companyAboutLink} target="_blank" rel="noreferrer"><p className="trademark__text-copyright">{linkText}.</p></a>
      <p className="trademark__text-copyright">All rights reserved.</p>
    </div>
  )
}

export default Trademark;
