import Header from '../../components/Header';
import Footer from '../../components/Footer';

import TermsAndConditionsHtml from './TermsAndConditionsHtml';

import { THeaderProps } from '../../components/Header';
import { TFooterProps } from '../../components/Footer';

type TProps = {
  header: THeaderProps,
  footer: TFooterProps
}

function TermsAndConditions(props: TProps) {
  const { header, footer } = props;

  return (
    <div className="terms-of-usage">
      <Header {...header} />
      <div className="bg-second-w">
        <TermsAndConditionsHtml />
      </div>
      <Footer {...footer} />
    </div>
  );
}

export default TermsAndConditions;
