import './styles.scss';

import logo from '../Header/TotM Logo.svg';
import Socials from '../Socials';
import Trademark from '../Trademark';

type TLinkProps = {
  text: string,
  link?: string,
}

const Link = function (props: TLinkProps) {
  const { text, link } = props;

  return (
    <a className="hover-color-secondary-m text-primary" target="_blank" href={link || "#"} rel="noreferrer">
      <h4 className="uppercase text-width-bold" >{text}</h4>
    </a>
  )
}

export type TFooterProps = {
  logoLink: string,
  whitepaperLink: string,
  privacyPolicyLink: string,
  termsAndConditionsLink: string,
}

const Footer = function(props: TFooterProps) {
  const { logoLink, whitepaperLink, privacyPolicyLink, termsAndConditionsLink } = props;
  return (
    <footer className="footer bg-second-y">
      <div className="super-container grid footer__grid">
        <div className="footer__logo flex w100">
          <a href={logoLink}>
            <img src={logo} />
          </a>
        </div>
        <div className="footer__links flex flex-align-center">
          <Link {...{text: 'Whitepaper', link: whitepaperLink }} />
          <Link {...{text: 'Privacy policy', link: privacyPolicyLink}} />
          <Link {...{text: 'Terms and conditions', link: termsAndConditionsLink}} />
        </div>
        <Socials {...{className: 'footer__grid_row footer__socials'}}/>
        <div className="footer__trademark flex">
          <Trademark />
        </div>
      </div>
    </footer>
  )
}

export default Footer;
