import Section from '../Section';
import Carousel from '../Carousel';
import BloggerTicket from '../BloggerTicket';

import './styles.scss'; 

import data from './data';

const BloggersSection = function() {
  const slides = data.map((unit: any, id: number) => (<BloggerTicket {...{...unit, key: id}} />) )

  return (
    <Section title="bloggers play our game">
      <div className="blg__carousel--s w100">
        <Carousel {...{slides, slidesPerPage: 1}} />
      </div>
      <div className="blg__carousel--m w100">
        <Carousel {...{slides, slidesPerPage: 2}} />
      </div>
      <div className="blg__carousel--d w100">
        <Carousel {...{slides, slidesPerPage: 3}} />
      </div>
    </Section>
  )
}

export default BloggersSection;
