import './styles.scss';

const Button = function(props: any) {
  const { className, bgClassName, children, onClick } = props;

  const handleClick = (e: any) => {
    e.preventDefault();

    if (onClick) {
      return onClick();
    }

    console.log('REDIRECT');
  };

  return (
    <button className={`clip-path-button button bg-primary ${className}`} onClick={handleClick}>
      <div className={`button__inner w100 h100 flex ${bgClassName}`}>
        <div className="button__inner-container">
          { children }
        </div>
      </div>
    </button>
  )
}

export default Button;
