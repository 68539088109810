import { useState } from 'react';

import Input from '../Input';
import Button from '../Button';

import './styles.scss';

const AWS_LAMBDA_URL="https://c8k40wo2ca.execute-api.eu-central-1.amazonaws.com/prod/email";

const emailValidator = (email: string): boolean => {
  const emailRegex = /[\w\.+?]+@([\w-]+\.)+[\w-]{2,}/g;

  return !!email.match(emailRegex)?.length;
}

const EMAIL_INVALID_MESSAGE = 'Email is invalid';

const validationMessageComponent = (message: string) => {
  return (
    <h4 className="form-validation-error bg-accent-o text-secondary-w">{message}</h4>
  )
}

const publishToLambda = async (data: any) => {
  try {
    await fetch(AWS_LAMBDA_URL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'no-cors'
  });
  } catch(err) {
    console.log("ERROR", err);
  }
}

type TProps = {
  onSubmit: Function,
};

const Form = function(props: TProps) {
  const { onSubmit } = props;

  const [validationFailMessage, setValidationFailMessage] = useState('');
  const [email, setEmail] = useState('');
  const [startValidation, setStartValidation] = useState(false);

  const validateEmail = (newEmail?: string) => {
    if(!emailValidator(newEmail || email)) {
      setValidationFailMessage(EMAIL_INVALID_MESSAGE);
      return false;
    }
    setValidationFailMessage('');
    return true;
  }

  const handleEmailChange = (newEmail: string) => {
    const email = newEmail;

    if (startValidation) {
      validateEmail(newEmail);
    }

    setEmail(email);
  }

  const handleClick = async () => {
    setStartValidation(true);
    if (validateEmail()) {
      console.log('SUCCESS CLICK');
      publishToLambda({email}); // use without await in order to skip spinner logic
      onSubmit();

      return;
    }
    console.log('CLICK');
  }

  return (
    <form className="w100 h100 flex flex-space-between flex-column form-cont">
      <div className="flex flex-space-between flex-column form-gap">
        <h3 className="text-secondary-y text-width-bold">Register your interest</h3>
        <Input {...{placeholder: 'Enter your email', className: 'text-secondary-y', onChange: handleEmailChange}} />
        { !!validationFailMessage && validationMessageComponent(validationFailMessage) }
      </div>
      <Button className="w100" bgClassName="text-primary bg-accent-lg hover-bg-color-secondary-y" onClick={handleClick}>
        <h3 className="text-weigth-bold uppercase">Submit</h3>
      </Button>
    </form>
  )
}

export default Form;
