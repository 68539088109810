import Section from '../Section';

import './styles.scss';

import map from './Map.svg';

const customTitle = function() {
  return (
    <span className="text-ttl-1 text-weigth-bold"><span className="text-weigth-bold text-ttl-1 text-secondary-m">500M+</span> downloads globally</span>
  )
}

const DownloadsSection = function() {
  return (
    <Section title={customTitle()}>
      <div className="flex-column w100 ds--gap flex">
        <img className="w100" src={map}/>
        <div className="w100 download-section__text-container">
          <div className="download-section__text-row-container flex">
            <h4 className="text-secondary-y">North America</h4>
            <h4 className="text-secondary-m txt-al--right">91M+</h4>
          </div>
          <div className="download-section__text-row-container flex">
            <h4 className="text-secondary-y">South America</h4>
            <h4 className="text-secondary-m txt-al--right">76M+</h4>
          </div>
          <div className="download-section__text-row-container flex">
            <h4 className="text-secondary-y">Europe</h4>
            <h4 className="text-secondary-m txt-al--right">124M+</h4>
          </div>
          <div className="download-section__text-row-container flex">
            <h4 className="text-secondary-y">Asia</h4>
            <h4 className="text-secondary-m txt-al--right">139M+</h4>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default DownloadsSection;
