import './styles.scss';

function Separator(props: { className: string }) {
  const {className} = props;

  return (
    <div className={`separator--clip-path ${className}`} />
  );
};

export default Separator;
