import Section from '../Section';

import './styles.scss';

import icon_heart from './icon_heart.svg';

import { handleLinkClick } from '../BeFirstSection';
import { AppleButton, GoogleButton } from './Buttons';
import { PLAYGENDARY_ABOUT_LINK, APP_STORE_LINK, PLAY_STORE_LINK } from '../../';

const handleAppleClick = () => {
  handleLinkClick(APP_STORE_LINK);
}

const handleGoogleClick = () => {
  handleLinkClick(PLAY_STORE_LINK);
}

const LegendaryGameSection = function() {
  const link = PLAYGENDARY_ABOUT_LINK;

  return (
    <Section title="legendary game is moving to web3">
      <div className="flex flex-space-between lg-game-container">
        <div>
          <p className="lg-game__text text-secondary-w">
          Tomb of the Mask is a famous arcade game with an infinite procedurally generated vertical labyrinth. Seeking adventure you get into a tomb where you find a strange mask. You put it on and suddenly realize that you can now climb walls - easily and promptly. And that's when all the fun begins.
          </p>
          <p className="lg-game__text text-secondary-w">
          We are introducing the all-new NFT mode -  soon available through the game update and will provide a rich Game-Fi experience to the millions of existing players and new users joining the game.
          </p>
          <div className="lg-game__container--flx">
            <p className="text-secondary-w">Made with</p>
            <img className="lg-game__image h100" src={icon_heart}/>
            <p className="text-secondary-w">in partnership with&nbsp;</p>
            <a className="hover-color-secondary-y text-secondary-w" href={link} target="_blank" rel="noreferrer"><p>Playgendary</p></a>
          </div>
        </div>
        <div className="flex lg-game__buttons flex-align-center mw50">
          <button onClick={handleGoogleClick} className="lg-game__button svg-button svg-button--apple-google">
            <GoogleButton />
          </button>

          <button onClick={handleAppleClick} className="lg-game__button svg-button svg-button--apple-google">
            <AppleButton />
          </button>
        </div>
      </div>
    </Section>
  );
};

export default LegendaryGameSection;
