import Section from '../Section';
import ExpandingGameTicket from '../ExpandingGameTicket';
import Carousel from '../Carousel';

import './styles.scss';

import tickets from './data';

function ExpandingGameSection () {
  const slides = tickets.map((unit: any, id: number) => (<ExpandingGameTicket{...{...unit, key: id}} />));

  return (
    <Section title="Expanding game to Metaverse">
      <div className="exp__default flex w100">
        { slides }
      </div>
      <div className="exp__carousel flex w100">
        <Carousel {...{slides, slidesPerPage: 1}} />
      </div>
    </Section>
  );
};

export default ExpandingGameSection;
