import EmptyTicket from '../EmptyTicket';

import './styles.scss'; 

type TProps = {
  gamer: {
    avatar: any,
    name: string,
    link: string,
  },
  video: {
    previewGif: any,
    link: string,
    viewsAmount: string,
  }
}

const BloggerTicket = function(props: TProps) {
  const { gamer, video } = props;
  return (
    <EmptyTicket className="blogger-ticket bg-second-m w100">
      <EmptyTicket style={{background: `url(${video.previewGif}) center no-repeat`, backgroundSize: 'cover'}} className="w100 h100">
        <div className="bt-shadow w100 h100 blogger-ticket-content flex w100 h100 flex-space-between flex-column">
          <a className="z-max hover-color-secondary-m text-secondary-y bt__link--height" href={gamer.link} target="_blank" rel="noreferrer">
            <div className="flex carousel-flex-gap flex-row">
                <img className="blogger-ticket-content__avatar h100 bt--custom-corner ticket-container" src={gamer.avatar}/>
                <h4 className="blogger-ticket-content__gamer-name text-weigth-bold">{gamer.name}</h4>
            </div>
          </a>

          <div className="flex w100 flex-column">
            <h2 className="text-secondary-y">{video.viewsAmount}</h2>
            <div className="flex w100 flex-space-between">
              <p className="text-secondary-y">views</p>
              <a className="z-max hover-color-secondary-m text-secondary-y" href={video.link} target="_blank" rel="noreferrer">
                <p>Play video {'>'}</p>
              </a>
            </div>
          </div>
        </div>
      </EmptyTicket>
    </EmptyTicket>
  )
}

export default BloggerTicket;
