import './styles.scss';

import PreloadContent from '../components/PreloadContent';

type TProps = {
  children?: any,
}

function App(props: TProps) {
  const {children} = props

  return (
    <div className="app">
      {children}
      <PreloadContent />
    </div>
  );
}

export default App;
