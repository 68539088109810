import _1 from './1.gif';
import _2 from './2.gif';
import _3 from './3.gif';
import _4 from './4.gif';
import _5 from './5.gif';
import _6 from './6.gif';

import spectexLogo from './spectex-logo.jpg';
import widgetLogo from './widget_logo.jpg';
import _3_Logo from './3_logo.jpg';
import str3123Logo from './str3123_logo.jpg';
import lonniedosLogo from './lonniedos_logo.jpg';
import gulNKimLogo from './gul_n_kim_logo.jpg';

const data = [
  {
    gamer: {
      avatar: spectexLogo,
      name: 'Spectex',
      link: 'https://www.youtube.com/c/SpectexGeometryDash', // twitch/youtube channel link
    },
    video: {
      previewGif: _1,
      link: 'https://www.youtube.com/watch?v=GpGeFh4gyLI',
      viewsAmount: '14,281,498',
    }
  },
  {
    gamer: {
      avatar: widgetLogo,
      name: 'Widget',
      link: 'https://www.youtube.com/channel/UCSWhdkEdKUeFdFgl7KFVRrQ', // twitch/youtube channel link
    },
    video: {
      previewGif: _2,
      link: 'https://www.youtube.com/watch?v=yKCDAoKXOIA',
      viewsAmount: '2,212,061',
    }
  },
  {
    gamer: {
      avatar: _3_Logo,
      name: '밍모',
      link: 'https://www.youtube.com/c/%EB%B0%8D%EB%AA%A8', // twitch/youtube channel link
    },
    video: {
      previewGif: _3,
      link: 'https://www.youtube.com/watch?v=llXRQweth1c',
      viewsAmount: '697,200',
    }
  },
  {
    gamer: {
      avatar: str3123Logo,
      name: 'str3123',
      link: 'https://www.youtube.com/user/str3123', // twitch/youtube channel link
    },
    video: {
      previewGif: _4,
      link: 'https://www.youtube.com/watch?v=qLeEo8rqkps',
      viewsAmount: '109,063',
    }
  },
  {
    gamer: {
      avatar: lonniedosLogo,
      name: 'lonniedos',
      link: 'https://www.youtube.com/c/lonniedos', // twitch/youtube channel link
    },
    video: {
      previewGif: _5,
      link: 'https://www.youtube.com/watch?v=nVh2dLW_KBY',
      viewsAmount: '78,707',
    }
  },
  {
    gamer: {
      avatar: gulNKimLogo,
      name: 'Guillaume & Kim',
      link: 'https://www.youtube.com/c/PokemonsMasters', // twitch/youtube channel link
    },
    video: {
      previewGif: _6,
      link: 'https://www.youtube.com/watch?v=eGDEpUzA21Q',
      viewsAmount: '67,190',
    }
  },
]

export default data;
