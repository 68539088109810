import { useState } from 'react';

import './styles.scss';

type TProps = {
  placeholder?: string,
  className?: string,
  startValidation?: boolean,
  onChange?: any,
}

const Input = function(props: TProps) {
  const {placeholder, className, onChange} = props;

  const [value, setValue] = useState('');

  const handleChange = (e: any) => {
    const value = e.target.value;
    setValue(value);
    onChange(value);
  }

  return (
    <>
      <input className={`do-w3__input ${className}`} placeholder={placeholder} type="text" spellCheck="false" value={value} onChange={handleChange} />
    </>
  )
}

export default Input;
