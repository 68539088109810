import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { THeaderProps } from '../../components/Header';
import { TFooterProps } from '../../components/Footer';

import PrivacyPolicyHtml from './PrivacyPolicyHtml';

type TProps = {
  header: THeaderProps,
  footer: TFooterProps
}

function PrivacyPolicy(props: TProps) {
  const { header, footer } = props;

  return (
    <div className="privacy-policy">
      <Header {...header} />
      <div className="bg-second-w">
        <PrivacyPolicyHtml />
      </div>
      <Footer {...footer}/>
    </div>
  );
}

export default PrivacyPolicy;
