import './styles.scss';

type TProps = {
  title?: any,
  children: any,
}

const Section = function(props: TProps) {
  const { title, children } = props;

  return (
    <section>
      <div className="super-container">
        { title && <h1 className="section__title text-secondary-w">{title}</h1> }
        <div className="section__content-container">
          {children}
        </div>
      </div>
    </section>
  )
}

export default Section;
