import Button from '../Button';

import './styles.scss';

export const handleClick = () => {
  document?.querySelector('#register-interest')?.scrollIntoView({
    behavior: 'smooth',
  });
}

function PlayNowSection() {

  return (
    <section className="play-now-section">
      <div className="play-now-section__bg-layout w100 h100">
        <div className="super-container h100 flex flex--just-cnt-cent">
          <div className="play-now-container flex">
            <h1 className="play-now-section__text text-secondary-y">Tomb of the mask NFT MODE</h1>
            <Button className="play-now-button" bgClassName= "bg-accent-c hover-bg-color-secondary-y" onClick={handleClick}>
              <h3 className="text-weigth-bold uppercase text-primary">register your interest</h3>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlayNowSection;
