import './styles.scss'; 

type TProps = {
  className?: string,
  style?: any,
  selectedP: boolean,
  id: number,
  onClick: any,
}

const CarouselDot = function(props: TProps) {
  const  { className, style, id, selectedP, onClick } = props;

  const handleClick = () => {
    onClick(id);
  }

  return (
    <button style={style} className={`clip-path-button carousel-dot carousel-dot--clip-path ${className} ${selectedP ? 'carousel-dot--selected' : 'carousel-dot--default'}`} onClick={handleClick}>
      { !selectedP && <div className={`clip-path-button w100 h100 carousel-dot--clip-path bg-primary`}></div> }
    </button>
  )
}

export default CarouselDot;
